
export class Driver {

  //
  public id: string | null = null;
  public accountType: string = null;
  public firstName: string = "";
  public lastName: string = "";
  public wilaya: string = "";
  public baladia: string = "";
  public phoneNumber: string = "";
  public fullAddress: string = "";
  public email: string = "";
  public birthday: string = "";
  public gender: string = "";
  public deliveryTime: string = "";
  public deliveryMethod: string = "";
  public createdAt: Date | any = "";
  public numberOfDeliveredPackages: string | number | null = "";
  public numberOfReturnedPackages: string | number | null = "";
  public available: string | boolean = "";
  public active: string | boolean = "";

  public static CollectionRef: string = "drivers";

  //
  public constructor(init?: Partial<Driver>) {
    Object.assign(this, init);
  }

  //
  public get _self() { return this; };

  public get getData(): any[] {
    let data: any = {};
    data.id = this.id || "";
    data.accountType = this.accountType || "";
    data.firstName = this.firstName || "";
    data.lastName = this.lastName || "";
    data.wilaya = this.wilaya || "";
    data.baladia = this.baladia || "";
    data.phoneNumber = this.phoneNumber || "";
    data.fullAddress = this.fullAddress || "";
    data.email = this.email || "";
    data.birthday = this.birthday || "";
    data.gender = this.gender || "";
    data.deliveryTime = this.deliveryTime || "";
    data.deliveryMethod = this.deliveryMethod || "";
    data.createdAt = this.createdAt || "";
    data.numberOfDeliveredPackages = this.numberOfDeliveredPackages || "";
    data.numberOfReturnedPackages = this.numberOfReturnedPackages || "";
    data.available = this.available || "";
    data.active = this.active || "";
    return data;
  };

  //
  public get _items(): any[] {
    let items = [];
    Object.keys(this.getData).forEach(item => {
      let text = item.split(/(?=[A-Z])/).join(" ");
      text = text.charAt(0).toUpperCase() + text.slice(1);
      let value = this[item] || "/";
      items.push({ text, value });
    })
    return items;
  };

  //
  public get _fullName() {
    return this.firstName + " " + this.lastName;
  };
  public get _wilaya_baladia() {
    return this.wilaya + " - " + this.baladia;
  };

  //
  public get _phoneNumber() {
    return this.phoneNumber + (this.active ? "" : " !");
  };

  //
  public static genderEnums: any = [
    { value: "", text: "/" },
    { value: "male", text: "Male" },
    { value: "female", text: "Female" },
  ];
  public get _gender() {
    return Driver.genderEnums.find(item => item.value == this.gender)?.text || this.gender;
  };

  //
  public static deliveryMethodEnums: any = [
    { value: "", text: "/" },
    { value: "bicycle", text: "vélo" },
    { value: "motorbike", text: "moto" },
    { value: "suv 4x4", text: "Voiture" }, //automobile
    { value: "van", text: "Fourgons/Van" },
    { value: "mini truck", text: "Mini-camion" },
    { value: "truck", text: "Camion" },
  ];

  public get _deliveryMethod() {
    return Driver.deliveryMethodEnums.find(item => item.value == this.deliveryMethod)?.text || this.deliveryMethod;
  };

  //
  public static yesNoBoolEnums: any = [
    { value: false, text: "No" },
    { value: true, text: "Yes" }
  ];
  public get _active() {
    return this.active ? "Active" : "Inactive";
  };

  //
  public static availableEnums: any = [
    { value: false, text: "No" },
    { value: true, text: "Yes" }
  ];

  private _availableEnums: any = [
    { value: false, text: "Pas disponible" },
    { value: true, text: "Disponible" }
  ];

  public get _available() {
    return Driver.availableEnums.find(item => item.value == this.available)?.text || this.available;
  };

  private get __available() {
    return this._availableEnums.find(item => item.value == this.available)?.text || this.available;
  }

  public get getDriverString() {
    return this.firstName + " " + this.lastName + " || " + this.phoneNumber + " || " + this.deliveryMethod + " || " + this.__available
  }

  public get getDriverOptions() {
    return this._fullName + " | " + this.phoneNumber
  }

  public get getDriverData(): DriverData {
    return {
      id: this.id,
      fullName: this._fullName,
      phoneNumber: this.phoneNumber
    }
  }

};



export interface DriverData {
  id: string,
  phoneNumber: string,
  fullName: string
}
