import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationSongsService {

  private soundEnabled: boolean = true;
  constructor() {
    this.loadSoundPreference();
  }

  private loadSoundPreference() {
    const savedPreference = localStorage.getItem('notificationSound');
    this.soundEnabled = savedPreference !== null ? JSON.parse(savedPreference) : true;
  }

  setSoundPreference(enabled: boolean) {
    this.soundEnabled = enabled;
    localStorage.setItem('notificationSound', JSON.stringify(enabled));
  }

  playNotificationSound() {
    if (!this.soundEnabled) return;

    const audio = new Audio('assets/sound/mixkit-correct-answer-tone-2870.wav');
    audio.play().catch(err => {
      console.error("Error playing sound:", err);
    });
  }


  playErrorSound() {
    if (!this.soundEnabled) return;

    const audio = new Audio('assets/sound/error.mp3');
    audio.play().catch(err => {
      console.error("Error playing sound:", err);
    });
  }
}
